html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.content-block {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.d-flex {
  display: flex;
  flex-basis: auto;
}

.flex-grow {
  flex-grow: 1;
  flex-shrink: 1;
}

.flex-shrink {
  flex-grow: 0;
  flex-shrink: 1;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.success {
  color: #8bc34a;
}

.warning {
  color: #ffc107;
}

.error {
  color: #f44336;
}

.popup-title {
  font-weight: 400;
  font-size: 18px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

::ng-deep .action-button.dx-button {
  height: 18px;
}

::ng-deep .action-button.dx-button-has-icon {
  min-width: 18px;
  max-width: 18px;
}

::ng-deep .action-button.dx-button .dx-icon {
  font-size: 16px;
  margin-right: 0;
  padding: 0;
}

::ng-deep .action-button.dx-button-has-icon .dx-button-content {
  padding: 0;
}

::ng-deep .dx-datagrid-filter-panel {
  max-width: 70vw !important;
  gap: 5px;
  justify-content: flex-start;
}
