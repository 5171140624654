/* You can add global styles to this file, and also import other style files */
@import "~material-icons/iconfont/material-icons.css";

a[target="_blank"]:before {
  content: "";
  background-image: url("/assets/icon_linkextern.png");
  background-size: contain;
  font-size: 14px;
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.2em;
  cursor: pointer;
}

a {
  text-decoration: none;
}

a:hover {
  color: #00698c;
  text-decoration: underline;
}

.template-text-box {
  background-color: #fff !important;
}
